<template>
    <v-row>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.gender"  :label="$t('hr.employee.gender')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.nationlaty"  :label="$t('hr.employee.nationality')"/>
      </v-col>
      <v-col md="6">
        <v-text-field dense disabled v-model="form.address"  :label="$t('hr.employee.address')"/>
      </v-col>

      <v-col md="3">
        <v-text-field dense disabled v-model="form.mother_name"  :label="$t('hr.employee.mother_name')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.passport_number"  :label="$t('hr.employee.passport')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.id"  :label="$t('hr.employee.id')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.note"  :label="$t('hr.employee.note')"/>
      </v-col>

      <v-col md="3">
        <v-text-field dense disabled v-model="form.iban"  :label="$t('hr.employee.iban')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.brch_swift"  :label="$t('hr.employee.swift')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.branch_number"  :label="$t('hr.employee.branch_num')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.branch_name"  :label="$t('hr.employee.branch_text')"/>
      </v-col>

      <v-col md="3">
        <v-text-field dense disabled v-model="form.coin"  :label="$t('hr.employee.currency')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.acount_number"  :label="$t('hr.employee.account_number')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.job"  :label="$t('hr.employee.job')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.zone"  :label="$t('hr.employee.area')"/>
      </v-col>

      <v-col md="3">
        <v-text-field dense disabled v-model="form.location"  :label="$t('hr.employee.location')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.alternate_phone_number"  :label="$t('hr.employee.alter_phone')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.phone_number"  :label="$t('hr.employee.phone')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.phone_number_2"  :label="$t('hr.employee.phone2')"/>
      </v-col>

      <v-col md="3">
        <v-text-field dense disabled v-model="form.guarantee_number"  :label="$t('hr.employee.ssn')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.guarantee_salary"  :label="$t('hr.employee.security_salary')"/>
      </v-col>
      <v-col md="3">
        <v-text-field dense disabled v-model="form.guarantee_card"
                      :label="$t('hr.employee.security_id_number')"/>
      </v-col>

    </v-row>
</template>

<script>
export default {
  name: "EmpData",
  props: {
    form: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
