<template>
  <v-row>
    <v-col>
      <table class="border-table">
        <thead class="border-top">
        <th>{{ $t('hr.employee.contract_type') }}</th>
        <th>{{ $t('hr.employee.start_date') }}</th>
        <th>{{ $t('hr.employee.end_date') }}</th>
        <th>{{ $t('status') }}</th>
        <th>{{ $t('hr.employee.salary') }}</th>
        <th>{{ $t('hr.employee.shift') }}</th>
        <th>{{ $t('hr.employee.required_vac') }}</th>
        <th></th>
        </thead>
        <tbody>
        <tr v-for="(contract, idx) in form.employee_contract">
          <td>{{ contract.contract.name }}</td>
          <td>{{ moment(contract.start_at).format('YYYY-MM-DD') }}</td>
          <td><div v-if="contract.terminate_date">{{ moment(contract.terminate_date).format('YYYY-MM-DD') }}</div></td>
          <td>{{ contract.status }}</td>
          <td>{{ contract.salary }}</td>
          <td>{{ contract.shift.name }}</td>
          <td>{{ contract.leaves_in_year }}</td>
          <td>
            <!-- <v-icon
              color="blue"
              style="cursor: pointer"
              @click="handleEdit(contract)"
            >mdi-pencil
            </v-icon> -->
          </td>
        </tr>
        <tr v-for="(emp, idx) in data">
          <td>
            <v-autocomplete :items="contracts" item-text="name" item-value="id" dense
                            :label="$t('hr.employee.contract_type')" 
                            v-model="data[idx].contract_id"></v-autocomplete>
          </td>
          <td>
            <v-text-field dense :label="$t('hr.employee.start_date')" outlined type="date"
                          v-model="data[idx].created_at"></v-text-field>
          </td>
          <td>
            <v-text-field dense :label="$t('hr.employee.end_date')" outlined type="date"
                          v-model="data[idx].terminate_date"></v-text-field>
          </td>
          <td>
            <v-text-field dense :label="$t('status')" disabled outlined v-model="data[idx].status"></v-text-field>
          </td>
          <td>
            <v-text-field dense :label="$t('hr.employee.salary')" outlined type="number" :rules="requiredRule()"
                          v-model="data[idx].salary"></v-text-field>
          </td>
          <td>
            <v-autocomplete :items="shifts" item-value="id" item-text="name" dense :label="$t('hr.employee.shift')"
                            outlined :rules="requiredRule()" v-model="data[idx].shift_id"></v-autocomplete>
          </td>
          <td>
            <v-text-field dense :label="$t('hr.employee.required_vac')" outlined :rules="requiredRule()" type="number" 
                          v-model="data[idx].leaves_in_year"></v-text-field>
          </td>
          <td>
            <v-icon
              color="red"
              style="cursor: pointer"
              @click="handleSave(idx)"
            >mdi-floppy
            </v-icon>
            <v-icon
              color="red"
              style="cursor: pointer"
              @click="handleDelete(null, idx)"
            >mdi-delete
            </v-icon>
          </td>
        </tr>
        <tr>
          <td style="border-color: #eaeaea" class="disabled">
            <v-btn rounded @click="handleAdd">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </td>
          <td style="border-color: #eaeaea"></td>
          <td style="border-color: #eaeaea"></td>
          <td style="border-color: #eaeaea"></td>
          <td style="border-color: #eaeaea"></td>
          <td style="border-color: #eaeaea"></td>
          <td style="border-color: #eaeaea"></td>
        </tr>
        </tbody>
      </table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import {requiredRule} from "@/helpers/validation-rules";

export default {
  name: "Contracts",
  computed: {
    ...mapGetters({
      contracts: 'employees/contracts',
      shifts: 'employees/shifts',
    }),
    moment() {
      return moment
    }
  },
  props: {
    form: {
      required: true
    }
  },
  data() {
    return {
      data: [
        {
          contract_id: '',
          employee_id: null,
          created_at: '',
          terminate_date: '',
          status: '',
          salary: '',
          shift_id: '',
          leaves_in_year: '',
        }
      ]
    }
  },
  mounted() {
    this.fetchContracts()
    this.fetchShifts()
  },
  methods: {
    requiredRule() {
      return requiredRule
    },
    ...mapActions({
      fetchContracts: 'employees/fetchContracts',
      fetchShifts: 'employees/fetchShifts',
      deleteEmployeeContract: 'employees/deleteEmployeeContract',
      saveEmployeeContract: 'employees/saveEmployeeContract',
    }),
    handleAdd() {
      this.data.push({
        contract_id: '',
        employee_id: null,
        created_at: '',
        terminate_date: '',
        status: '',
        salary: '',
        shift_id: '',
        leaves_in_year: '',
      })
    },
    async handleSave(idx) {
      this.data[idx].employee_id = this.form.id
      console.log(this.form.id);
      await this.saveEmployeeContract(this.data[idx])
      this.form.employee_contract.push(this.data[idx])
      this.data.splice(idx, 1)
    },
    async handleDelete(id, idx) {
      if (id) {
        await this.deleteEmployeeContract(id)
        this.data.splice(idx, 1)
      } else {
        this.data.splice(idx, 1)
      }
    },
    handleEdit(){

    },
  }
}
</script>

<style scoped lang="scss">
th {
  padding: 1.4rem;
}

table {
  width: 100%;
}

/* Apply border styles to the table */
.border-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;
}

/* Apply border styles to table header cells (th) */
.border-table th {
  border: 1px solid #ccc;
  padding: 8px;
}

/* Apply border styles to table data cells (td) */
.border-table td {
  border: 1px solid #ccc;
  padding: 8px;
}
</style>
