<template>
  <v-row justify="center">
    <v-col sm="12" md="6">
      <v-row>
        <v-col sm="12" md="12">
          {{ $t('hr.employee.salary_rules') }}
        </v-col>
        <v-col sm="12" md="12">
          <table class="bordered-table">
            <thead>
              <tr>
                <th>{{ $t('hr.employee.rules.rule') }}</th>
                <th>{{ $t('hr.employee.rules.type') }}</th>
                <th>{{ $t('hr.employee.rules.value') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="rule in form.contract_rules">
                <td>{{ rule.name }}</td>
                <td>{{ rule.rule_type }}</td>
                <td>{{ rule.value }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-col>
    <v-col sm="12" md="6">
      <v-row>
        <v-col sm="12" md="12">
          {{ $t('hr.employee.employee-rules') }}
        </v-col>
        <v-col sm="12" md="12">
          <table class="bordered-table">
            <thead class="border-top">
              <th>{{ $t('hr.employee.rules.rule') }}</th>
              <th>{{ $t('hr.employee.rules.type') }}</th>
              <th>{{ $t('hr.employee.rules.value') }}</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="(rule, idx) in form.personal_rules  || [{}]">
                <td>{{ rule.name }}</td>
                <td>{{ rule.changing_type }}</td>
                <td>{{ rule.value }}</td>
                <td>
                  <v-btn icon @click="handleDelete(rule.id, idx)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-for="(emp, idx, rule,) in data">
                <td>
                  <v-select v-model="ruleName" :items="rulesListN" :label="$t('hr.employee.contract_type')" outlined dense
                    @change="choiseRule()"></v-select>

                </td>
                <td>
                  <v-text-field v-if="ruleName" v-model="trigger.type" disabled outlined></v-text-field>
                </td>
                <td>
                  <v-text-field v-if="ruleName" v-model="trigger.value" disabled outlined></v-text-field>
                </td>
                <td>
                  <v-btn icon @click="handleDelete(null, idx)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn icon @click="handleSave(idx)">
                    <v-icon>mdi-floppy</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td style="border-color: #eaeaea" class="disabled"><v-btn rounded
                    @click="handleAdd"><v-icon>mdi-plus</v-icon></v-btn></td>
                <td style="border-color: #eaeaea"></td>
                <td style="border-color: #eaeaea"></td>
                <td style="border-color: #eaeaea"></td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "SalaryRules",
  props: {
    form: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      rules: 'employees/rules'
    })
  },
  data() {
    return {
      trigger: [],
      ruleName: "",
      rulesList: [],
      rulesListN: [],
     
      data: [
        {
          rule_id: '',
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchRules: 'employees/fetchRules',
      saveEmployeeRule: 'employees/saveEmployeeRule',
      deleteEmployeeRule: 'employees/deleteEmployeeRule',
    }),
    handleAdd() {
      this.data.push({ rule_id: '' })
    },
    async handleDelete(id, idx) {
      if (id) {
        await this.deleteEmployeeRule({
          rule_id: id,
          employee_contract_id: this.form.employee_contract_id
        })
        this.data.splice(idx, 1)
      } else {
        this.data.splice(idx, 1)
      }
    },
    async handleSave(idx) {
      this.data[idx].employee_contract_id = this.form.employee_contract_id
      this.data[idx].rule_id = this.trigger.id
      res = await this.saveEmployeeRule(this.data[idx])
      this.data.personal_rules.push(this.data[idx])
      this.data.splice(idx, 1)


    },
    choiseRule() {
      
      for (let i = 0; i < this.rulesList.length; i++) {
        if (this.rulesList[i].name == this.ruleName) {
          this.trigger = {
            name: this.rulesList[i].name,
            id: this.rulesList[i].id,
            type: this.rulesList[i].rule_type,
            value: this.rulesList[i].value,
          };
        }

      }
    },
  },
    async created() {

      await axios.get("/hr/rule").then((res) => {
        this.rulesList = res.data.rules;
        for (let i = 0; i < this.rulesList.length; i++) {
          
          this.rulesListN.push(this.rulesList[i].name);
        }
      });

    }
  
}
</script>

<style scoped>
th {
  padding: 1.4rem;
}

td {
  padding: 8px;
}

table {
  width: 100%;
}

.bordered-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;

}

.bordered-table th {
  border: 1px solid #ccc;
}

.bordered-table td {
  border: 1px solid #ccc;
}
</style>
