import { render, staticRenderFns } from "./PaymentCheck.vue?vue&type=template&id=253dce00&scoped=true&"
import script from "./PaymentCheck.vue?vue&type=script&lang=js&"
export * from "./PaymentCheck.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "253dce00",
  null
  
)

export default component.exports