<template>
  <v-row>
    <v-col sm="12">
      <v-data-table :dark="$store.state.isDarkMode"
        :headers="checkHeaders"
        :items="payments">

      </v-data-table>
    </v-col>
    <v-col sm="12">

    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PaymentCheck",
  props: {
    form: {
      required: true
    }
  },
  mounted() {
    this.fetchAS(this.form.account_id)
  },
  methods: {
    ...mapActions({
      fetchAS: 'employees/fetchAccountStatement'
    })
  },
  computed: {
    ...mapGetters({
      payments: 'employees/payments',
      checkHeaders: 'employees/checkHeaders'
    })
  }
}
</script>

<style scoped>

</style>
