<template>
  <v-container>
    <v-card elevation="0">
      <v-card-text >
        <v-container>
          <v-row  >
            <v-col md="9">
              <v-row>
                <v-col md="3">
                  <h4 color="white" class="mb-5">{{ $t('hr.employee.first_name') }}: <span style="font-weight: 100">{{ form.name }}</span></h4>
                </v-col>
                <v-col md="4">
                  <h4 class="mb-5">{{ $t('hr.employee.mid_name') }}: <span style="font-weight: 100">{{ form.middle_name }}</span> </h4>                 
                </v-col>
                <v-col md="5">
                  <h4 class="mb-5">{{ $t('hr.employee.last_name') }}: <span style="font-weight: 100">{{ form.last_name }}</span></h4>
                </v-col>
                <v-col md="3">
                  <h4 class="mb-5">{{ $t('hr.employee.sign') }}: <span style="font-weight: 100">{{ form.serial_number }}</span></h4>
                </v-col>
                <v-col md="4">
                  <h4 class="mb-5"> {{ $t('hr.employee.code')}}: <span style="font-weight: 100">{{ form.code }}</span> </h4>
                </v-col>
                <v-col md="5">
                  <h4 class="mb-5">{{ $t('hr.employee.email')}}: <span style="font-weight: 100">{{ form.email }}</span></h4>
                </v-col>
                <v-col md="3">
                  <h4 class="mb-5">{{ $t('hr.employee.base_sal')}}: <span style="font-weight: 100">{{ form.base_salary }}</span></h4>
                </v-col>
                <v-col md="4">
                  <h4 class="mb-5">{{ $t('hr.employee.contract')}}: <span style="font-weight: 100">{{ form.contract.name }}</span></h4>
                </v-col>
                <v-col md="5">
                  <h4 class="mb-5">{{ $t('hr.employee.shift')}}: <span style="font-weight: 100">{{ form.shift.name }}</span> </h4>
                </v-col>
                <v-col md="3">
                  <h4 class="mb-5">{{ $t('hr.employee.required_vac')}}: <span style="font-weight: 100">{{ form.leaves_in_year }}</span></h4>
                </v-col>
                <v-col md="4">
                  <h4 class="mb-5">{{ $t('hr.employee.emp_date')}}: <span style="font-weight: 100">{{ form.employment_date }}</span></h4>
                </v-col>
                <v-col md="5">
                  <h4 class="mb-5">{{ $t('hr.employee.payment_start_date')}}: <span style="font-weight: 100">{{ form.calculate_start_date }}</span></h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="3">
              <v-img
                v-if="form.personal_pic"
                :src="getImgUrl(form.personal_pic)"
                alt="Selected Image"
                style="cursor: pointer; width: 200px; height: 200px; object-fit: cover;"
              ></v-img>
              <img
                v-else
                src="@/assets/pricing.jpg"
                alt="Placeholder Image"
                style="cursor: pointer; width: 200px; height: 200px; object-fit: cover;"
              />
            </v-col>
            <v-col sm="12">
            </v-col>
            <v-col >
              <v-tabs  :dark="$store.state.isDarkMode" background-color="var(--bg-color--background)" v-model="selectedTab" :active-class="'active'">
                <v-tab class="fix-design">{{ $t('hr.employee.data') }}</v-tab>
                <v-tab class="fix-design">{{ $t('hr.employee.contracts') }}</v-tab>
                <v-tab class="fix-design">{{ $t('hr.employee.loans') }}</v-tab>
                <v-tab class="fix-design">{{ $t('hr.employee.salary_rules') }}</v-tab>
                <v-tab class="fix-design">{{ $t('hr.employee.swear') }}</v-tab>
                <v-tab class="fix-design">{{ $t('hr.employee.payment_check') }}</v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <v-tabs-items v-model="selectedTab">
                <v-container style="overflow: auto">
                  <v-tab-item>
                    <EmpData :form="form"></EmpData>
                  </v-tab-item>
                  <v-tab-item>
                    <Contracts :form="form"></Contracts>
                  </v-tab-item>
                  <v-tab-item>
                    <Loans :form="form"></Loans>
                  </v-tab-item>
                  <v-tab-item>
                    <SalaryRules :form="form"></SalaryRules>
                  </v-tab-item>
                  <v-tab-item>
                    <Swear :form="form"></Swear>
                  </v-tab-item>
                  <v-tab-item>
                    <PaymentCheck :form="form"></PaymentCheck>
                  </v-tab-item>
                </v-container>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
  
</template>

<script>
import {requiredRule} from "@/helpers/validation-rules";
import EmpData from "@/views/dashboard/modules/HR/employees/tabs/EmpData.vue";
import Contracts from "@/views/dashboard/modules/HR/employees/tabs/Contracts.vue";
import Loans from "@/views/dashboard/modules/HR/employees/tabs/Loans.vue";
import SalaryRules from "@/views/dashboard/modules/HR/employees/tabs/SalaryRules.vue";
import Swear from "@/views/dashboard/modules/HR/employees/tabs/Swear.vue";
import PaymentCheck from "@/views/dashboard/modules/HR/employees/tabs/PaymentCheck.vue";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'EditEmployee',
  components: {PaymentCheck, Swear, SalaryRules, Loans, Contracts, EmpData},
  computed: {
    ...mapGetters({
      contracts: 'employees/contracts',
      shifts: 'employees/shifts',
    })
  },
  methods: {
    ...mapActions({
      fetchContracts: 'employees/fetchContracts',
      fetchShifts: 'employees/fetchShifts',
      fetchEmployee: 'employees/fetchEmployee',
    }),
    requiredRule() {
      return requiredRule
    },
    getImgUrl(item) {
            let domain = "https://bahlol-back.incentive-dev.com/"
            let item2 = item.replace("public", "storage")
            return domain + item2
        }
  },
  
  data() {
    return {
      employee_id: this.$route.params.id,
      form: [],
      selectedTab: null,
      valid: false
    }
  },
  mounted() {
    this.fetchContracts()
    this.fetchShifts()
  },
  
  watch: {}
  ,
 async created(){
    const res = await axios.get(`/hr/employee/${this.employee_id}`);
    let da = res.data.data;
    this.form = da;
    console.log(this.form);
    console.log(this.form.employee_contract.salary);
  }
}
</script>

<style scoped>
.active {
  /* background-color: lightblue; */
  /* color: white; */
}

.fix-design {
  /* border: 1px solid grey; */
   /* border-top-left-radius: 10px; Rounded top-left corner */
   /* border-top-right-radius: 10px; Rounded top-right corner */

}
</style>
