<template>
  <v-container>
  <table class="border-table">
    <thead class="border-top">
    <th>{{ $t('hr.employee.swear-type') }}</th>
    <th>{{ $t('hr.vacation.note') }}</th>
    <th></th>
    </thead>
    <tbody>
    <tr v-for="(custody, idx) in form.custodies || [{}]">
      <td>{{ custody.custody.name }}</td>
      <td>{{ custody.note }}</td>
      <td>
        <v-icon
          color="red"
          style="cursor: pointer"
          @click="handleDelete(custody.id, idx)"
        >mdi-delete
        </v-icon>
      </td>
    </tr>
    <tr v-for="(emp, idx) in data">
      <td>
        <v-select @change="setCustdy()" :items="custodyListN" item-text="name" item-value="id" dense v-model="custodyName" ></v-select>
      </td>
      <td>
        <v-text-field  dense v-model="data[idx].note"></v-text-field>
      </td>
      <td>
        <v-btn icon @click="handleDelete(null, idx)">
          <v-icon color="red">mdi-delete</v-icon>
        </v-btn>
        <v-btn icon @click="handleSave(idx)">
          <v-icon
            color="green"
            style="cursor: pointer"
          >mdi-floppy
          </v-icon>
        </v-btn>
      </td>
    </tr>
    <tr>
      <td style="border-color: #eaeaea" class="disabled">
        <v-btn rounded @click="handleAdd">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </td>
      <td style="border-color: #eaeaea"></td>
      <td style="border-color: #eaeaea"></td>
    </tr>
    </tbody>
  </table>
</v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  async created() {

await axios.get("/hr/custody").then((res) => {
  this.custodyList = res.data.custodies;
  for (let i = 0; i < this.custodyList.length; i++) {
    
    this.custodyListN.push(this.custodyList[i].name);
  }
});

},

  name: "Swear",
  props: {
    form: {
      required: true
    }
  },
  mounted() {
    this.fetchCustodies()
  },
  data() {
    return {
      custodyList:[],
      custodyListN:[],
      custodyName:"",
      trigger:[],
      data: [
        {
          employee_id: '',
          custody_id: '',
          note: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      custodies: 'employees/custodies'
    })
  },
  methods: {
    ...mapActions({
      fetchCustodies: 'employees/fetchCustodies',
      saveEmployeeCustody: 'employees/saveEmployeeCustody',
      deleteEmployeeCustody: 'employees/deleteEmployeeCustody',
    }),
    setCustdy(){
      for (let i = 0; i < this.custodyList.length; i++) {
        if (this.custodyList[i].name == this.custodyName) {
          this.trigger = {
            name: this.custodyList[i].name,
            id: this.custodyList[i].id,
           
          };
        }

      }
    },
    handleAdd() {
      this.data.push({
        employee_id: '',
        custody_id: '',
        note: ''
      })
    },
    async handleSave(idx) {
      this.data[idx].employee_id = this.form.id
      this.data[idx].custody_id = this.trigger.id
      await this.saveEmployeeCustody(this.data[idx]);
      this.form.employee_contract.push(this.data[idx])
      // this.data.splice(idx, 1)
    },
    async handleDelete(id, idx) {
      if (id) {

        await this.deleteEmployeeCustody(id)
        this.data.splice(idx-1, 1)
      } else {
        this.data.splice(idx, 1)
      }
    }
  }
}
</script>

<style scoped>
th {
  padding: 1.4rem;
}

table {
  width: 100%;
}

/* Apply border styles to the table */
.border-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;
}

/* Apply border styles to table header cells (th) */
.border-table th {
  border: 1px solid #ccc;
  padding: 8px;
}

/* Apply border styles to table data cells (td) */
.border-table td {
  border: 1px solid #ccc;
  padding: 8px;
}
</style>
