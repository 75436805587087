<template>
  <v-data-table :dark="$store.state.isDarkMode"
  :headers="loanHeaders"
  :items="form.loan"
  >
  </v-data-table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Loans",
  props: {
    form: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      loanHeaders: 'employees/loanHeaders'
    })
  }
}
</script>

<style scoped>

</style>
